<template>
    <main>
        <span class="font-weight-bolder">Seleccione un método anticonceptivo</span>
        <div class="container mt-2">
            <label v-for="item in metodo_planificacion" :key="item.id">
                <input type="radio" :value="item.id" name="radio" v-model="id_metodo" />
                <span>{{ item.name }}</span>
            </label>
        </div>
        <span class="text-danger d-block" v-if="$v.id_metodo.$error && $v.id_metodo.$dirty">Debe completar el
            formulario
        </span>
    </main>
</template>
<script>
import Toast from '../../../../components/common/utilities/toast';
import historiaMetodoPlanificacionService from "../../../../services/historiaMetodoPlanificacionService";
import { isEmpty } from "lodash";
import { required } from 'vuelidate/src/validators';
import metodosPlanificacionService from "../../../../services/metodosPlanificacionService";
export default {
    props: ['idHistoria', 'finalizada', 'usuario'],
    data() {
        return {
            metodo_planificacion: {},
            id_metodo: ''
        }
    },
    validations() {
        return {
            id_metodo: { required }
        }
    },
    methods: {
        async save() {
            try {

                this.$v.$touch();

                if (this.$v.$invalid) return;

                let metodo = {
                    id_historia: this.idHistoria,
                    id_metodo: this.id_metodo
                }

                this.LoaderSpinnerShow();

                await historiaMetodoPlanificacionService.store(metodo);

                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
            } catch (error) {
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        }
    },
    watch: {
        finalizada: async function (value) {
            if (value === 'N') {
                const response = await historiaMetodoPlanificacionService.showByHc(this.idHistoria);
                if (!isEmpty(response.data)) {
                    this.id_metodo = response.data.id_metodo;
                }
            }
        }
    },
    async created() {
        const response_metodo = await metodosPlanificacionService.index({
            sexo: this.usuario.sexo
        });

        this.metodo_planificacion = response_metodo.data;

        if (this.finalizada === 'N') {
            const response = await historiaMetodoPlanificacionService.showByHc(this.idHistoria);
            if (!isEmpty(response.data)) {
                this.id_metodo = response.data.id_metodo;
            }
        }
    }
}
</script>
<style scoped>
.container form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.container label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
}

.container label input {
    position: absolute;
    left: -9999px;
}

.container label input:checked+span {
    background-color: #414181;
    color: white;
}

.container label input:checked+span:before {
    box-shadow: inset 0 0 0 0.4375em #00005c;
}

.container label span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
    color: #414181;
    font-size:12px;
}

.container label span:hover {
    background-color: #d6d6e5;
}

.container label span:before {
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #fff;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #00005c;
}
</style>
