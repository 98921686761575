<template>
    <main>
         <div class=" ">
            <div class="card-header bg-light "><span><i class="fa fa-first-aid"> </i> Historial Anticoncepcioal</span> </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                        <tr class="table-primary">
                            <th>Metodo </th>
                            <th>Desde</th>
                            <th>Hasta</th>
                            <th>Prescrito en</th>
                            <th>Problemas</th>
                        </tr>
                        <tr v-for="item in items" :key="item.id" >
                            <td>{{item.metodo_utilizado}}</td>
                            <td>{{ item.desde }}</td>
                            <td>{{ item.hasta }}</td>
                            <td>{{ item.prescrito_en }}</td>
                            <td>{{ item.problemas }}</td>
                        </tr>                                
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import {isEmpty} from 'lodash';
export default {
    props:['idHistoria','idUsuario','data'],
     
    data() {
        return{
            items: [],
        }
    },
    async created() {
        if(!isEmpty(this.data)){
            this.items = this.data.map(x=>{
                return {
                    id:x.id,
                    metodo_utilizado:x.metodo_utilizado,
                    desde:x.desde,
                    hasta:x.hasta,
                    prescrito_en:x.prescrito_en,
                    problemas:x.problemas,

                }
            });        
        } 
        
    },
}
</script>
