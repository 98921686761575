<template>

    <div>
        <div v-for="(instrumento,index) in data" :key="index">
        <div class="card-header bg-light "><span><i class="fa fa-pills"> </i> {{ instrumento.instrumento.nombre }}</span> </div>
        <div class="card-body">
            <div class="table-responsive mt-2">
                <table class="table table-bordered table-striped">
                    <tr v-for="item in instrumento.detalles" :key="item.id">
                        <th>{{item.opcion.pregunta.pregunta}}</th>
                        <th>{{item.opcion.respuesta}} (= {{item.opcion.puntuacion}})</th>
                        
                    </tr>
                </table>
                <div class="row col-12"><span>Puntaje: {{instrumento.puntuacion_total}} </span></div>
            </div>
        </div>
        </div>
    </div>

</template>
<script>
export default {
    props: ['idHistoria','finalizada','data'],    
}
</script>